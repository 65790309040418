import { Card as ACard, Col, Modal, Row, Skeleton, Tag } from 'antd';
import React, { Fragment, forwardRef, useCallback } from 'react';

import { ErrorBoundary } from 'components/CategoryNav/ErrorBoundary';
import { Price } from 'components/MarketItem/Details/Price';
import { QuantityWrapper } from 'components/MarketItem/QuantityInput/QuantityWrapper';
import ProductNote from 'components/ProductNote';
import { getImageByCategory } from 'containers/MarketPage/ImageByCategory';
import { ContainerState } from 'containers/ProductDetailsPage/types';
import QuantityInput from 'containers/QuantityInput';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import translations from 'translations';
import { TrackingAddToCartAddFromType } from 'types/schema';
import { allowDecimalQuantity } from 'utils/allowDecimalQuantity';
import { getLimitedTimeOfferPercent } from 'utils/discount';
import utilMessages from 'utils/messages';
import Image from '../Image/Image';
import Favorite, { Favorite as FavoriteContainer, FavoriteIcon } from '../MarketItem/Favorite';
import Heart from './Heart.svg';
import { generateSlugFromQueryName } from 'utils/urls/categoriesPathSegment';
import { generateUrlBrandDetail } from '../../utils/generateBrandDetailUrl';

const Container = styled.section`
  min-height: 400px;
`;
const Header = styled.h1`
  border-left: 2px solid #2e904e;
  padding-left: 8pt;
  margin-bottom: 6pt;
  margin-top: 10pt;
  font-size: 20px;
  font-weight: 500;
  line-height: 22px;
`;
const Body = styled.div`
  padding-left: 8pt;
`;
const Card = styled(ACard)`
  .ant-card-body {
    padding: 0;
  }
  && img {
    border-radius: 4px;
  }
`;
const AddCart = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${QuantityWrapper} {
    input {
      width: 65px !important;
    }
  }
`;
const TableInfo = styled.div`
  padding-top: 10pt;
  padding-bottom: 10pt;
  margin-top: 10pt;
  margin-bottom: 6pt;
  border-top: 1px solid #e8ebf2;
  border-bottom: 1px solid #e8ebf2;
`;
const InfoRow = styled.div`
  width: 100%;
`;
const Info = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-top: ${({ marginTop }) => marginTop || 0};
  align-items: ${({ alignItems }) => alignItems || 'top'};
`;
const Label = styled.span`
  color: rgba(0, 0, 0, 0.54);
  min-width: 70pt;
`;
const Value = styled.span`
  color: ${({ color }) => (color ? color : 'rgba(0, 0, 0, 0.87)')};
`;

const ExclusiveWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const TagExclusiveWrapper = styled.div`
  color: #424242;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 8px;
`;
const TagExclusive = styled.div`
  flex-shrink: 0;
  padding: 4px 8px;
  background: #e6d8bb;
  color: #806e44;
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
  width: fit-content;
`;

const discountStyle = {
  background: '#ff4852',
  border: 'none',
  borderRadius: 0,
  borderTopRightRadius: 10,
  borderBottomRightRadius: 10,
  color: '#ffffff',
  fontSize: 16,
  marginRight: 0,
  padding: 5,
  position: 'absolute',
  top: 12,
  left: 0,
} as any;

const tagStyle = {
  background: '#ff4852',
  border: 'none',
  borderRadius: 0,
  borderTopLeftRadius: 10,
  borderBottomLeftRadius: 10,
  color: '#ffffff',
  fontSize: 16,
  marginRight: 0,
  padding: 5,
} as any;

const tagContainerStyle = {
  position: 'absolute',
  bottom: 12,
  right: 0,
  flexDirection: 'column',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
} as any;

const componentPermisisons = {
  addToCartPermission: 'ORDER_PLACE',
  addFavoritePermission: 'BUYER_FAVORITE_ITEM_ADD',
};

interface IProps {
  product: ContainerState['product'];
  added: number;
  adding: boolean;
  permissions: string[];
  onToggleFavorite: () => void;
  onOpenNote: () => void;
  onDeleteNote: () => void;
  gotoSignIn: () => void;
}

const Note = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 300;
  color: #8c8c8c;
`;

const Product: React.ForwardRefRenderFunction<HTMLElement, IProps> = (props, ref) => {
  const loggedIn = !!localStorage.getItem('token');
  const lang = localStorage.getItem('lang') || 'vi';
  const { product, permissions, onToggleFavorite, onOpenNote, onDeleteNote, gotoSignIn } = props;
  const {
    originalPrice,
    price,
    note,
    category,
    supplier,
    preservationLocal,
    origin: productOrigin,
    inStock,
    uomLocal,
    description,
    vat,
    allowAddToCart,
    private: isPrivate,
    allowPurchase,
    brand,
  } = product;

  const itemDiscountExists = originalPrice > price;

  const onClickDeleteNote = useCallback(() => {
    Modal.confirm({
      autoFocusButton: null,
      okButtonProps: {
        danger: true,
      },
      title: translations(utilMessages.titleRemoveNote),
      content: <p style={{ fontWeight: 300, marginTop: 16 }}>*{translations(utilMessages.noteRemoveNote)}</p>,
      okText: translations(utilMessages.yes),
      cancelText: translations(utilMessages.no),
      onOk: () => onDeleteNote(),
    });
  }, [onDeleteNote]);

  const generatePathname = (category) => {
    if (category.parent.name) {
      return category.name
        ? generateSlugFromQueryName(`${category.parent?.name}/${category.name}`, lang)
        : generateSlugFromQueryName(category.parent?.name, lang);
    }
    return '';
  };

  return (
    <ErrorBoundary>
      <Container>
        <Row gutter={8}>
          <Col lg={8} xs={24}>
            <Card
              cover={
                <Image
                  zoomIn
                  responsive
                  src={product.imageUrl || getImageByCategory(category)}
                  alt={product.name ?? translations(utilMessages.productDetailImage)}
                  width="386px"
                  height="386px"
                />
              }
              bordered={false}
            >
              {permissions.indexOf(componentPermisisons.addFavoritePermission) > -1 && allowPurchase && (
                <Favorite
                  renderAsImage={false}
                  onClick={onToggleFavorite}
                  alignto="right"
                  favorite={product.isFavorite ? 'true' : 'false'}
                />
              )}
              {!loggedIn && allowPurchase && (
                <FavoriteContainer type="secondary" shape="circle" onClick={gotoSignIn}>
                  <FavoriteIcon renderAsImg={false} src={Heart} />
                </FavoriteContainer>
              )}
              {itemDiscountExists ? (
                <Tag style={discountStyle}>{getLimitedTimeOfferPercent(originalPrice, price)}</Tag>
              ) : null}
              <div style={tagContainerStyle}>
                {(product.tags || []).includes('NEW_PRODUCTS') && (
                  <div>
                    <Tag style={{ ...tagStyle, background: '#40A9FF' }}>
                      <FormattedMessage {...utilMessages.newTag} />
                    </Tag>
                  </div>
                )}
                {(product.tags || []).includes('BOOK_IN_ADVANCE') && (
                  <div>
                    <Tag style={{ ...tagStyle, background: '#E5C03C' }}>
                      <FormattedMessage {...utilMessages.bookInAdvanceTag} />
                    </Tag>
                  </div>
                )}
              </div>
            </Card>
          </Col>
          <Col lg={10} xs={24}>
            {product.loading ? (
              <Skeleton loading={product.loading} paragraph={{ rows: 3, width: '100%' }} active />
            ) : (
              <Fragment>
                <Header>{product.name}</Header>
                <Body>
                  <AddCart>
                    {isPrivate ? (
                      allowPurchase ? (
                        <Price product={product} />
                      ) : (
                        <ExclusiveWrapper>
                          <TagExclusiveWrapper>
                            <TagExclusive>
                              <FormattedMessage {...utilMessages.exclusive} />
                            </TagExclusive>
                            <FormattedMessage {...utilMessages.onlyForSpecialBusiness} />
                          </TagExclusiveWrapper>
                        </ExclusiveWrapper>
                      )
                    ) : (
                      <Price product={product} />
                    )}
                    {permissions.indexOf(componentPermisisons.addToCartPermission) > -1 &&
                      product.inStock &&
                      allowAddToCart && (
                        <QuantityInput
                          product={product}
                          size="small"
                          allowDecimal={allowDecimalQuantity(product.uom)}
                          addFrom={TrackingAddToCartAddFromType.ProductDetail}
                        />
                      )}
                    {!loggedIn && allowAddToCart && (
                      <QuantityInput
                        product={product}
                        allowDecimal={false}
                        addFrom={TrackingAddToCartAddFromType.ProductDetail}
                      />
                    )}
                  </AddCart>
                  <pre>{description}</pre>
                  <TableInfo>
                    <InfoRow>
                      <Info>
                        <Label>
                          <FormattedMessage {...utilMessages.vat} />
                        </Label>
                        <Value>{vat}%</Value>
                      </Info>
                    </InfoRow>
                    <InfoRow>
                      <Info>
                        <Label>
                          <FormattedMessage {...utilMessages.uom} />
                        </Label>
                        <Value>{uomLocal}</Value>
                      </Info>
                      <Info>
                        <Label>
                          <FormattedMessage {...utilMessages.availability} />
                        </Label>
                        <Value color={inStock ? '#2e904e' : '#FE5043'}>
                          {inStock ? (
                            <FormattedMessage {...utilMessages.inStock} />
                          ) : (
                            <FormattedMessage {...utilMessages.outStock} />
                          )}
                        </Value>
                      </Info>
                    </InfoRow>
                    <InfoRow>
                      <Info>
                        <Label>
                          <FormattedMessage {...utilMessages.origin} />
                        </Label>
                        <Value>{productOrigin || 'N/A'}</Value>
                      </Info>
                      <Info>
                        <Label>
                          <FormattedMessage {...utilMessages.preservation} />
                        </Label>
                        <Value>{preservationLocal || 'N/A'}</Value>
                      </Info>
                    </InfoRow>
                    <InfoRow>
                      <Info>
                        <Label>
                          <FormattedMessage {...utilMessages.supplier} />
                        </Label>
                        <Value>{supplier?.name}</Value>
                      </Info>
                      <Info>
                        <Label>
                          <FormattedMessage {...utilMessages.category} />
                        </Label>
                        <Link to={`/${generatePathname(category)}`}>
                          <Value color="#008fe5">{category?.nameLocal}</Value>
                        </Link>
                      </Info>
                      {!!brand?.name && (
                        <Info>
                          <Label>
                            <FormattedMessage {...utilMessages.brandName} />
                          </Label>
                          <Link to={generateUrlBrandDetail(brand?.id || '',brand?.name || '')}>
                            <Value color="#008fe5">{brand.name}</Value>
                          </Link>
                        </Info>
                      )}
                      <Info marginTop={'16px'} alignItems={!note && 'center'}>
                        <Label ref={ref}>
                          <FormattedMessage {...utilMessages.note} />
                        </Label>
                        <Value>
                          <ProductNote
                            note={note && <Note>{note}</Note>}
                            onClickOpenNote={onOpenNote}
                            onClickDeleteNote={onClickDeleteNote}
                          />
                        </Value>
                      </Info>
                    </InfoRow>
                  </TableInfo>
                  {(product.tags || []).includes('BOOK_IN_ADVANCE') && (
                    <p>
                      <FormattedMessage {...utilMessages.bookInAdvanceDescription} />
                    </p>
                  )}
                </Body>
              </Fragment>
            )}
          </Col>
        </Row>
      </Container>
    </ErrorBoundary>
  );
};

export default forwardRef<HTMLElement, IProps>(Product);
